import request from '@/../utils/request'
const api_name = '/iotechserver/CommandController'
const api_name1 = '/iotechserver/JLBreakerController'

export default {
  //指令下发
  sendCommand(command) {
    return request({
      url: `${api_name}/sendCommand`,
      method: 'post',
      data: command
    })
  },
  //获取该设备类型所有的指令
  getDeviceCommand(typeId) {
    return request({
      url: `/iotechserver/deviceCommand/getCommandByTypeId/${typeId}/null`,
      method: 'get',
    })
  },

  // 三项智能网关断路器 关闭/启动 接口
  dlqDevice(status, deviceId) {
    return request({
      url: `${api_name1}/startCommand`,
      method: 'post',
      data: {
        deviceId: deviceId,
        status: status,
      }
    })
  },

  //获取设备指令下发历史
  getDeviceCommandHistory(deviceId, current, limit) {
    return request({
      url: `/iotechserver/deviceCommandHistory/getDeviceCommandHistory/${deviceId}/${current}/${limit}`,
      method: 'get',
    })
  },

  //通过设备id获取设备指令信息
  getCommandListByDeviceId(deviceId) {
    return request({
      url: `/iotechserver/deviceCommand/getCommandByDeviceId/${deviceId}/null`,
      method: 'get',
    })
  },

  //获取该账号下所有带指令的设备号
  getAllCommandDevice() {
    return request({
      url: `/iotechserver/deviceCommand/getAllCommandDevice`,
      method: 'get',
    })
  }
}