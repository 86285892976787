import request from '@/../utils/request'
const api_name = '/iotechserver/deviceLinkage'

export default {
  // 查询账号下所有设备联动信息
  pageLinkageDeviceList(current, limit, deviceId) {
    return request({
      url: `${api_name}/pageLinkageDeviceList/${current}/${limit}/${deviceId}`,
      method: 'get'
    })
  },

  // // 通过设备id获取设备联动信息：
  // getLinkageDeviceByDeviceId(deviceId, current, limit) {
  //   return request({
  //     url: `${api_name}/getLinkageDeviceByDeviceId/${deviceId}/${current}/${limit}`,
  //     method: 'get'
  //   })
  // },

  // 通过关系id获取联动信息：
  getLinkageDeviceByRelationId(relationId) {
    return request({
      url: `${api_name}/getLinkageDeviceByRelationId/${relationId}`,
      method: 'get'
    })
  },

  // 添加设备联动信息：
  addLinkageDevice(arr) {
    return request({
      url: `${api_name}/addLinkageDevice`,
      method: 'post',
      data: {
        "deviceLinkagesList":arr
      }
    })
  },

  // 更新设备联动信息：
  updateLinkageDevice(arr) {
    return request({
      url: `${api_name}/updateLinkageDevice`,
      method: 'post',
      data:{
        "deviceLinkagesList":arr
      }
    })
  },


  // 删除设备联动信息：
  deleteLinkageDevice(relationId) {
    return request({
      url: `${api_name}/deleteLinkageDevice/${relationId}`,
      method: 'get'
    })
  },

  // 通过设备id获取联动触发条件类型
  getconditionsByDeviceId(deviceId) {
    return request({
      url: `${api_name}/getconditionsByDeviceId/${deviceId}`,
      method: 'get'
    })
  },







  // // 查询地图指定设备类型设备
  // getDeviceTypeAboutMap(typeId) {
  //     return request({
  //         url: `${api_name}/getDeviceTypeAboutMap/${typeId}`,
  //         method: 'get'
  //     })
  // },
  // // 查询地图单个设备
  // getOneDeviceTypeAboutMap(typeId) {
  //     return request({
  //         url: `${api_name}/getOneDeviceTypeAboutMap/${typeId}`,
  //         method: 'get'
  //     })
  // },

}