<template>
  <div id="app">
    <div style="display:flex;">
      <div class="item">
        <el-input v-model="deviceId" placeholder="请输入设备id" clearable></el-input>
      </div>
      <div class="item">
        <el-button type="primary" @click="searchDevice">搜索</el-button>
      </div>
      <div class="item">
        <el-button type="success" @click="showDialog('add')" v-if="userRightList.addJuris=='1'">添加联动关系</el-button>
      </div>
      <div class="item" style="margin-left:auto">
        <el-select v-model="tableTitle" multiple collapse-tags placeholder="选择类型" @change="selectTitle">
          <el-option v-for="item in tableTitleList" :key="item.value" :label="item.title" :value="item.title">
          </el-option>
        </el-select>
      </div>
    </div>
        
    <div class="table">
      <el-table :data="deviceInfoList" style="width: 100%" border :max-height="700">
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column v-if="tableTitle.includes('触发设备')" label="触发设备" align="center">
          <template slot-scope="scope">
            <el-tag type="primary" v-if="item.conditionsId!=''" v-for="item in scope.row" style="margin: 0 5px">{{item.conditionsId}}【{{item.conditionsType}}】</el-tag>
          </template>
        </el-table-column>

        <el-table-column v-if="tableTitle.includes('联动设备')" label="联动设备" align="center">
          <template slot-scope="scope">
            <el-tag type="primary" v-if="item.linkageId!=''" v-for="item in scope.row" style="margin: 0 5px">{{item.linkageId}}【{{item.linkageOperation}}】</el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="tableTitle.includes('联动条件数')" label="联动条件数" width="150" align="center">
          <template slot-scope="scope">
            <span>{{scope.row[0].conditionsNum}}</span> 个
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" align="center" width="200" v-if="userRightList.updateJuris=='1'">
          <template slot-scope="scope">
            <el-button @click="showDialog('edit',scope.row)" type="success" size="small" v-if="userRightList.updateJuris=='1'">编辑</el-button>
            <el-button type="danger" size="small" @click="deletRelation(scope.row)" v-if="userRightList.delJuris=='1'">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

    </div>
    
    <!-- 添加/编辑弹框 -->
    <el-dialog :title="dialogTitle" :visible.sync="showEdit" width="50%" style="background:rgba(0,0,0,0.6)">
      <div class="content">
        <div class="content-box">
          <div class="left">
            <h2 class="title">触发设备：</h2>
            <el-select v-model="triggerDevice" filterable placeholder="请选择触发设备" @change="selectedDevice" clearable collapse-tags style="width:90%">
              <el-option v-for="item in options1" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>

            <!-- 选择指令按钮 -->
            <el-radio-group style="margin-top:10px" v-for="(item, index) in conditionsList" :key="index" v-model="conditionsType" size="mini" @change="choseConditions(item)">
              <el-radio-button :label="item" style="margin-right:10px"></el-radio-button>
            </el-radio-group>

            <!-- 选择触发条件  v-if="deviceOrderList.length!=0"-->
            <!-- <div style="margin:5px 0;width:100%;height:90px;overflow:scroll">
              <el-checkbox-group style="margin-top:10px" v-for="(item, index) in conditionsList" :key="index" v-model="checkedConditionsList" size="mini" @change="choseConditions(item)">
                <el-checkbox :label="item"></el-checkbox>
              </el-checkbox-group>
            </div> -->

          </div>
          <!-- 显示设备 -->
          <div class="right">
            <el-tag v-if="tag!=''" v-for="(tag,index) in tagList" :key="index" class="tags" closable @close="deletThis(tag)">
              {{tag.conditionsId}}【{{tag.conditionsType}}】
            </el-tag>
          </div>
        </div>

        <div style="display:flex">
          <h2 class="title" style="margin-left:15px;">联动条件数：</h2>
          <el-input v-model="number" placeholder="请选择触发设备数" style="width:20%" @blur="inputbox"></el-input>
        </div>
        <div class="content-box">
          <div class="left">
            <h2 class="title">联动设备：</h2>
            <el-select v-model="linkageDevice" filterable placeholder="请选择联动设备" :disabled="control" collapse-tags @change="linckedDevice" clearable @clear="closeOrder" style="width:90%">
              <el-option v-for="item in options2" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>

            <!-- 选择指令按钮 -->
            <el-radio-group style="margin-top:10px" v-for="(item, index) in deviceOrderList" :key="index" v-model="order" size="mini" v-if="deviceOrderList.length!=0" @change="choseOrder">
              <el-radio-button :label="item.commandName" style="margin-right:10px"></el-radio-button>
            </el-radio-group>
          </div>
          <!-- 显示设备 -->
          <div class="right">
            <el-tag v-for="(tag,index) in tagList1" :key="index" class="tags" closable @close="deletThis1(tag)" @click="changeDeviceOrder(tag)">
              {{tag.linkageId}}【{{tag.linkageOperation}}】
            </el-tag>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="showEdit = false">取 消</el-button>
        <el-button type="primary" @click="addSubmitInfo">确 定</el-button>
      </div>
    </el-dialog>
    <el-pagination :current-page.sync="current" :page-size="limit" background :total="total" layout="total, prev, pager, next, jumper" style="padding-top: 10px;  text-align: center" @current-change="currentPage"></el-pagination>

  </div>
</template>

<script>
import DeviceLinkage from '@/api/manageApi/DeviceLinkage'
import DeviceInfoManagement from '@/api/manageApi/DeviceInfoManagement'
import Command from '@/api/mapBuidingApi/Command'
// 查权限
import LoginUserJuris from '@/api/managementApi/LoginUserJuris'
// 查账号
import user from "@/api/manageApi/User";
export default {
  data() {
    return {
      tableTitleList: [{
        id: 0,
        title: '触发设备',
      }, {
        id: 1,
        title: '联动设备',
      }, {
        id: 2,
        title: '联动条件数',
      }],
      tableTitle: ['触发设备', '联动设备', '联动条件数'],

      conditionsList: [], // 触发条件类型集合
      conditionsType: [],  // 选择的触发条件



      control: false,   // 用户没有选择指令时，不能选择下一个设备
      current: 1,
      limit: 10,
      total: 0,
      deviceId: null,
      flag: '',   // 判断点击的是编辑还是添加

      showEdit: false,
      deviceInfoList: [],     // table 
      options1: [],

      options2: [],
      tagList: new Set(),          // 选择的触发设备集合
      tagList1: new Set(),         // 选择的联动设备集合

      deviceObj: {},        // 操作指令

      number: 1,            // 触发设备条件数

      triggerDevice: '',    // 触发设备  value
      linkageDevice: '',    // 关联设备  value

      linkDevice: '',       // 点击编辑关联设备

      dialogTitle: "",      //弹框标题

      deviceOrderList: [],  // 指令列表
      order: '',  // 选择的设备指令

      editInfoList: [], // 编辑信息

      relationId: '',
      userRightList: [],

      operator: "",//当前用户(操作者)
    };
  },

  created() {
    this.getUsers()
    this.getLinkageDevice()
    this.getAllDevice()
  },

  methods: {
    selectTitle(value) {
      if (value.length < 1) {
        this.$message.warning("至少选择1个类型")
        this.tableTitle.push('触发设备')
      } else {
        this.tableTitle = value
      }
    },
    // 获取用户权限
    async getUserRight(user) {
      this.userRightList = []
      const { data: res } = await LoginUserJuris.getMenuFromUserName(user)
      this.userRightList = res.data.jurisList
      if (this.userRightList.length == 0) {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      } else {
        for (let i = 0; i < res.data.jurisList.length; i++) {
          if (res.data.jurisList[i].menuName == "deviceInfoManagement") {
            this.userRightList = res.data.jurisList[i]
          }
        }
      }
      if (user == "admin") {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      }

    },
    // 获取用户名
    async getUsers() {
      const { data: res } = await user.loginUserInfo()
      this.operator = res.data.userName
      this.getUserRight(this.operator)
    },

    // 删除联动设备信息
    deletRelation(row) {
      console.log(row[0].relationId);
      DeviceLinkage.deleteLinkageDevice(row[0].relationId).then(res => {
        console.log(res);
        if (res.data.message == "删除成功") {
          this.$message.success(res.data.message)
        } else {
          this.$message.error("删除失败")
        }
        this.getLinkageDevice()
      })
    },
    // 查询设备
    searchDevice() {
      if (this.deviceId == '') {
        this.deviceId = null
      }
      this.getLinkageDevice()
    },
    // 输入框
    inputbox() {
      this.tagList = Array.from(this.tagList)
      if (this.number > this.tagList.length) {
        this.$message({
          type: 'warning',
          message: '触发设备数量不能大于选择数量',
        })
        this.number = 1
      }
    },
    // 删除点击的标签1
    deletThis(value) {
      this.tagList = new Set(this.tagList)
      this.tagList.delete(value)
      this.tagList = Array.from(this.tagList)
    },

    // 选择触发条件
    choseConditions(value) {
      this.tagList = Array.from(this.tagList)

      let isExist = false;
      for (const i in this.tagList) {
        console.log(this.tagList[i])
        if (this.tagList[i].conditionsId == this.triggerDevice
          && this.tagList[i].conditionsType == value) {
          isExist = true;
        }
      }

      if (!isExist) {
        this.tagList = new Set(this.tagList)
        let obj = {}
        obj.conditionsId = this.triggerDevice
        obj.conditionsType = value
        this.tagList.add(obj)

        this.conditionsList = []
        this.triggerDevice = ''
      } else {
        this.$message.warning("改设备下已绑定此触发条件！")
      }
    },

    // 选择触发设备
    async selectedDevice(value) {
      console.log(value);
      this.conditionsType = ''
      const { data: res } = await DeviceLinkage.getconditionsByDeviceId(value)
      console.log(res);
      this.conditionsList = res.data.conditionsList
      if (this.conditionsList.length == 0) {
        this.$message.warning('该设备无触发条件！')
      }
      // this.tagList = new Set(this.tagList)
      // this.tagList.add(value)
      // console.log(this.tagList)
    },

    // 根据设备类型查询所有设备
    async getAllDevice() {
      const { data: res } = await DeviceInfoManagement.getDeviceIdList(null)
      this.options1 = res.data.list;
      console.log(this.options1);
      const { data: ress } = await Command.getAllCommandDevice()
      this.options2 = ress.data.deviceList;
    },

    // 查询账号下所有设备联动信息
    async getLinkageDevice() {
      const { data: res } = await DeviceLinkage.pageLinkageDeviceList(this.current, this.limit, this.deviceId)
      this.deviceInfoList = res.data.deviceLinkageList
      this.total = res.data.total
      // DeviceLinkage.pageLinkageDeviceList(this.current, this.limit, this.deviceId).then(res => {
      //   this.deviceInfoList = res.data.data.deviceLinkageList
      //   console.log(this.deviceInfoList);
      //   this.total = res.data.data.total
      // })
    },

    // 分页
    currentPage(value) {
      this.getLinkageDevice()
    },

    // 删除点击的标签2
    deletThis1(value) {
      this.tagList1 = new Set(this.tagList1)
      this.tagList1.delete(value)
      this.deviceOrderList = []
      this.linkageDevice = ''
    },
    closeOrder() {
      this.deviceOrderList = []
    },

    // 选择联动设备
    linckedDevice(value) {
      // 清空
      this.editInfoList = []    // 选择联动设备后清空存放联动设备和指令的数组，
      this.deviceObj = {}
      this.order = ""
      // 根据id查指令
      Command.getCommandListByDeviceId(value).then(res => {
        // 设备指令列表
        this.deviceOrderList = res.data.data.deviceCommands

        if (this.deviceOrderList.length != 0) {
          this.deviceObj.linkageId = value
        } else {
          this.$message({
            type: 'warning',
            message: '该设备无操作指令，无法添加！',
          })
          this.linkageDevice = ''
        }
      })
    },

    // 选择指令
    choseOrder(value) {
      if (this.editInfoList.linkageId) {
        this.editInfoList.linkageOperation = value
      } else {
        this.tagList1 = new Set(this.tagList1)
        this.deviceObj.linkageOperation = value
        this.tagList1.add(this.deviceObj)
      }
    },

    // 修改设备指令   （点击设备回显指令）
    changeDeviceOrder(value) {
      this.linkageDevice = value.linkageId
      this.deviceOrderList = []
      this.editInfoList = value
      this.order = value.linkageOperation
      Command.getCommandListByDeviceId(value.linkageId).then(res => {
        this.deviceOrderList = res.data.data.deviceCommands
      })
    },

    //显示编辑设备联动关系弹框
    showDialog(msg, data) {
      this.deviceOrderList = []
      this.conditionsList = []
      this.flag = msg
      this.showEdit = true
      // 添加
      if (msg == 'add') {
        this.dialogTitle = "添加设备联动关系"
        this.tagList = new Set()
        this.tagList1 = new Set()
        this.triggerDevice = ''
        this.number = 1
        this.linkageDevice = ''
        // 编辑
      } else if (msg == 'edit') {
        this.triggerDevice = ''
        this.linkageDevice = ''
        this.tagList = Array.from(new Set())
        this.tagList1 = Array.from(new Set())
        this.dialogTitle = "编辑设备联动关系"
        console.log(data)
        this.relationId = data[0].relationId;
        for (const i in data) {
          if (data[i].conditionsId != null && data[i].conditionsId != undefined && data[i].conditionsId != "") {
            let obj = {}
            obj.conditionsId = data[i].conditionsId
            obj.conditionsType = data[i].conditionsType
            this.tagList.push(obj)
          }
          if (data[i].linkageId != null && data[i].linkageId != undefined && data[i].linkageId != "") {
            let obj = {}
            obj.linkageId = data[i].linkageId
            obj.linkageOperation = data[i].linkageOperation
            this.tagList1.push(obj)
          }
          if (data[i].conditionsNum != null && data[i].conditionsNum != undefined && data[i].conditionsNum != "") {
            this.number = data[i].conditionsNum
          }
        }
      }
    },


    addSubmitInfo() {
      let len = 0;
      this.tagList = Array.from(this.tagList)
      this.tagList1 = Array.from(this.tagList1)
      if (this.tagList.length >= this.tagList1.length) {
        len = this.tagList.length
      } else {
        len = this.tagList1.length
      }
      let arr = []
      console.log(len)
      for (let i = 0; i < len; i++) {
        let obj = {}
        if (this.tagList[i] != undefined && this.tagList[i] != "") {
          obj.conditionsId = this.tagList[i].conditionsId
          obj.conditionsType = this.tagList[i].conditionsType
        } else {
          obj.conditionsId = ""
          obj.conditionsType = ""
        }
        obj.conditionsNum = this.number
        if (this.tagList1[i] != undefined && this.tagList1[i] != "") {
          obj.linkageId = this.tagList1[i].linkageId
          obj.linkageOperation = this.tagList1[i].linkageOperation
        } else {
          obj.linkageId = ""
          obj.linkageOperation = ""
        }
        if (this.flag == 'edit' && this.relationId != "") {
          obj.relationId = this.relationId
        }
        obj.operator = this.operator
        arr.push(obj)
      }
      console.log(arr)
      if (this.flag == 'add') {
        if (this.number > this.tagList.length) {
          this.$message.warning("触发设备数不能大于触发设备数量！");
        } else if (this.tagList1.length == 0 || this.tagList.length == 0) {
          this.$message.warning("触发设备或联动设备不能为空！");
        } else {
          DeviceLinkage.addLinkageDevice(arr).then(res => {
            console.log(res)
            if (res.data.message == "添加成功") {
              this.$message.success("添加成功！")
              this.showEdit = false
              this.getLinkageDevice()
            }
          })
        }
      } else if (this.flag == 'edit') {
        if (this.number > this.tagList.length) {
          this.$message.warning("触发设备数不能大于触发设备数量！");
        } else if (this.tagList1.length == 0 || this.tagList.length == 0) {
          this.$message.warning("触发设备或联动设备不能为空！");
        } else {
          DeviceLinkage.updateLinkageDevice(arr).then(res => {
            console.log(res)
            if (res.data.message == "修改成功") {
              this.$message.success("修改成功!")
              this.showEdit = false
              this.getLinkageDevice()
            }
          })
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.rows {
  margin: 10px 10px 10px 0;
}
.title {
  font-size: 18px;
  padding: 15px 0;
}
.content {
  width: 100%;
  height: 450px;

  .content-box {
    box-shadow: 1px 1px 5px #ccc;
    margin: 0 0 20px 10px;
    padding: 10px;
    height: 180px;
    display: flex;

    .left {
      border-right: 1px solid rgb(224, 221, 221);
      height: 100%;
      width: 40%;
    }
    .right {
      width: 60%;
      height: 100%;
      overflow: scroll;
      .linkage {
        height: 30px;
        line-height: 30px;
        display: flex;
        .tag {
          width: 80%;
        }
        .order {
          width: 20%;
          text-align: center;
          color: rgb(157, 168, 179);
        }
      }
    }
  }
}
.tags {
  cursor: pointer;
  margin: 5px;
}
/deep/.el-dialog__title {
  font-size: 22px;
}
.item {
  margin: 20px 5px;
  }
</style>